@media only screen and (min-width:4501px) and (max-width: 6000px) {
/*25*/
}
@media only screen and (min-width:3100px) and (max-width: 4500px) /*33*/{

  
}
@media only screen and (min-width:2701px) and (max-width: 3100px) {
    /*50*/

}
@media only screen and (min-width:2001px) and (max-width: 2700px) {
/*67%*/
 
}
@media only screen and (min-width: 1801px) and (max-width: 2000px) {
    /*75%*/

}
@media only screen and (min-width:1601px) and (max-width: 1800px) {
    /*80%*/

}
@media only screen and (min-width:1517px) and (max-width: 1600px) {
    /*90%*/
 
}
@media (max-width:1330px){

}
@media (max-width:1300px){

}
@media (max-width:1199px){
.queing-table-section {
        width: 100%;
    padding: 0 70px;
}
.dashboard-div {
    width: 100%;
    padding: 0 0;
    display: inline-block;
}
.dashboard-top-section {
    grid-template-columns: auto;
    grid-row-gap: 20px;
}
.grid-view-full-section {
    grid-template-columns: 316px auto;
}
.upload_file_div input#fileselect {
    padding: 155px 5% 0 29%;
}
}

@media (max-width:991px)
{
.logo-section {
    margin: 25px 0 0 0;
}
.logo-section img {
    width: 72px;
}
.form-logo-section img {
    width: 92px;
    height: 92px;
}
.home-register-section {
    padding: 10px 0 15px 0;
}
.home-register-form-div{
	padding: 0px 30px;
}
.home-register-form-div.selct-form-div {
    padding: 0 80px;
}
.next-btn-section {
    margin: 0px 0 50px 0px;
}
.home-register-form-div.design-menu-sec.design-logo-sec {
    padding: 0px 50px;
}
.home-register-form-div.design-menu-sec.design-logo-sec h4{
	    margin: 0 0 30px 0;
}
.queing-table-section {
    width: 100%;
    padding: 0px 15px;
	    display: inline;
}
.dashboard-div {
    width: 100%;
    padding: 0 70px;
    display: inline;
}

}
@media (max-width:980px){
.detail-inner-full-section-right .design-mock-outer .design-mock-outer-image-box-logo {
    top: 90px;
}
.upload_file_div input#fileselect {
    padding: 155px 5% 0 26%;
}
}
@media (max-width:767px){
	/*mobile res*/
	.form-logo-section img {
    width: 80px;
    height: 80px;
}
.logo-section img {
    width: 60px;
}
}
@media (max-width:766px)
{
.home-register-form-div {
    width: 100%;
}
.home-register-form-div.design-menu-sec.design-logo-sec {
    padding: 0px 15px;
}
.design-logo-grid-outer.design-alphabets-grid-outer .logo-grid-box-img{
	    width: 100%;
}
.quee-table-pagination ul.pagination{
	    padding: 0 0px 0 0;
}
.grid-view-full-section {
    grid-template-columns: auto;   
}
.dashboard-top-section{
	text-align: center;
}
.medialibrary-grid-section{
	    justify-content: center;
}
form.home-register-form.home-register-form .col-md-12 {
    padding: 0px;
}
.design-logo-grid-inner{
	    grid-row-gap: 20px;
}
.queing-table-inner table thead th{
	    font-size: 19px;
}
.queing-table-inner table tbody tr td{
	        font-size: 17px;
}
.thankyou-sec-inner h2 {
    font-size: 60px;
    line-height: 65px;
    padding: 0px;
    margin: 60px 0 75px;
}
.thankyou-sec-inner h5 {
    font-size: 24px;
    text-transform: uppercase;
    padding: 0px 0px 100px 0px;
}

/*for radio*/
.radio-section.radio-1-section .radio-section-inner {
    width: 100%;
}
.radio-section.radio-2-section .radio-section-inner {
    width: 100%;
}
.color_box{
       width: 100px;
}
/*for radio END*/
}
@media (max-width:640px)
{
.home-register-form-div.design-menu-sec.design-logo-sec.design-alphabet-sec {
    padding: 0px;
}
.home-register-section {
    padding: 4px 0 15px 0;
}
.footer-btn-large{
	    display: table;
	    width: 100% !important;
		padding: 0px;
}
.footer-btn-large .footer-left-btn, .footer-btn-large .footer-center-btn {
    width: 50%;
    float: left;
    margin: 0 0 50px 0;
	    text-align: center;
}
.footer-btn-large .footer-right-btn {
    width: 100%;
    margin: 0 auto;
    float: none;
    text-align: center;
}
.detail-inner-full-section {
    grid-template-columns: 100%;
    grid-gap: 0;
}
.upload_file_div input#fileselect {
    padding: 155px 5% 0 34%;
}
.design-logo-grid-outer.design-graphics-grid-outer .design-logo-grid-inner .logo-grid-box .logo-grid-box-img img {
    width: 72px !important;
    height: 45px !important;
}
.home-register-form-div.selct-form-div {
    padding: 0px;
}
.terms-list-outer{
	grid-template-columns: 1fr;
	    grid-gap: 0px;
    justify-items: left;
    margin: 15px 0;
}
.terms-list-outer .terms-list-grid {
    margin: 0px;
}
.checkbox-div.register-checkbox-div label {
    font-size: 13px;
}
}

@media (max-width:480px)
{
.home-register-form-div {
    padding: 0px 0px;
}
.home-register-form-div.selct-form-div {
    padding: 0 0px;
}
.design-menu-inner ul li a, .footer-btn, .design-menu-inner ul li button {
    width: 290px;
}
.footer-btn a.black_btn , .footer-btn button.black_btn {
    padding: 12px 35px;
}
.design-menu-inner ul li a, .design-menu-inner ul li button{
	    height: 75px;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-img{
	    height: 75px;
}
.design-logo-grid-outer {
    min-height: 450px;
}
.design-icon-sec .design-logo-grid-outer {
    min-height: 350px;
}
.home-register-form-div.design-menu-sec.design-logo-sec .col-md-12 {
    padding: 0px;
}
.home-register-form-div.design-menu-sec.design-logo-sec h4{
	    padding: 0px;
}
.design-layout-grid-outer{
	    min-height: 330px;
}
.grid-side-image-square {
    right: 35px;
}
.upload_file_div input#fileselect {
    padding: 155px 5% 0 19%;
    padding-top: 155px;
    padding-right: 5%;
    padding-bottom: 0px;
    padding-left: 19%;
}
.color_box {
    width: 100px;
}
}
@media (max-width:375px)
{
 .design-menu-inner ul li a, .design-menu-inner ul li button, .footer-btn {
    width: 100%;
}
.design-logo-grid-inner .logo-grid-box .logo-grid-box-img{
	    height: 60px;
}
.design-logo-grid-outer {
    min-height: 400px;
}
.design-icon-sec .design-logo-grid-outer {
    min-height: 330px;
}
.queing-table-menu ul li button {
	    font-size: 16px;
}
.detail-inner-full-section-right .design-mock-outer .design-mock-outer-image-box-logo {
    top: 70px;
}
}
@media (max-width:360px)
{
}
@media (max-width:359px)
{
.footer-btn a.black_btn, .footer-btn button.black_btn {
    padding: 12px 28px;
}
.queing-table-menu ul li button {
	    font-size: 14px;
}
.color_box {
    width: 90px;
}
}
@media (max-width:320px)
{


}

